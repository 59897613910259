import type React from "react";
import { useEffect, useRef, useState } from "react";
import type { SxStyleProp } from "theme-ui";

import {
	Button,
	Counter,
	Icon,
	Image,
	type ImageType,
	Key,
	TriggerButton,
} from "@Components";
import { useBreakpoint, useOnKeyEvent } from "@Hooks";
import {
	commonGalleryButtonMobileStyles,
	decreaseIndex,
	increaseIndex,
	updateScrollImage,
	useActiveIndex,
	useOnceScrolled,
} from "../utils";

interface FullScreenGalleryProps {
	images: ImageType[];
	activeImageIndex?: number;
	height?: string;
	quality?: number;
}

const commonButtonDesktopStyles: SxStyleProp = {
	display: ["none", "flex"],
	padding: "s",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
};

const imageGap = 4; // this is gap between images 4xs

export const FullScreenGallery: React.FC<
	React.PropsWithChildren<FullScreenGalleryProps>
> = ({ images, activeImageIndex, height = "100vh", quality }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const size = images.length;

	if (!size) {
		return null;
	}

	const scrollActiveIndex = useActiveIndex(containerRef, imageGap);
	const [activeIndex, setActiveIndex] = useState(activeImageIndex || 0);
	const wasScrolled = useOnceScrolled(containerRef);
	const { isMobile } = useBreakpoint();
	const loadedImages = useRef<Record<number, boolean>>({});

	/** Functions */

	// 01. Set position in the beginning
	useEffect(() => {
		// Scroll event if we have activeIndex
		if (activeIndex > 0 && containerRef?.current && isMobile) {
			const infoContainerElement = containerRef.current.getBoundingClientRect();
			const containerWidth = infoContainerElement.width;
			containerRef.current.scrollLeft += containerWidth * activeIndex;
		}
	}, []);

	// 02. Keyboard navigation
	useOnKeyEvent(
		Key.ArrowLeft,
		() =>
			isMobile
				? updateScrollImage({
						containerRef,
						newActiveIndex: decreaseIndex(activeIndex, size),
						imageGap,
					})
				: setActiveIndex(decreaseIndex(activeIndex, size)),
		[activeIndex],
	);

	useOnKeyEvent(
		Key.ArrowRight,
		() =>
			isMobile
				? updateScrollImage({
						containerRef,
						newActiveIndex: increaseIndex(activeIndex, size),
						imageGap,
					})
				: setActiveIndex(increaseIndex(activeIndex, size)),
		[activeIndex],
	);

	// 03. Change index on scroll event.
	useEffect(() => {
		if (wasScrolled && scrollActiveIndex !== activeIndex) {
			setActiveIndex(scrollActiveIndex);
		}
	}, [scrollActiveIndex]);

	const isImagePrepared = (index: number) =>
		activeIndex === index ||
		increaseIndex(activeIndex, size) === index ||
		decreaseIndex(activeIndex, size) === index;

	return (
		<div
			sx={{
				display: ["block", "grid"],
				gridTemplateColumns: "auto 1fr auto",
				userSelect: "none",
				height,
			}}
		>
			<div>
				<TriggerButton
					data-id="fullscreen-gallery-previous"
					onTrigger={() => setActiveIndex(decreaseIndex(activeIndex, size))}
					sx={commonButtonDesktopStyles}
				>
					<Icon size="40" name="Actions/MovePrevious" />
				</TriggerButton>
				<Button
					data-id="fullscreen-gallery-mobile-previous"
					variant="Secondary"
					size="36"
					icon="Actions/MovePrevious"
					onClick={() =>
						updateScrollImage({
							containerRef,
							newActiveIndex: decreaseIndex(activeIndex, size),
							imageGap,
						})
					}
					sx={{
						...commonGalleryButtonMobileStyles,
						left: "3xs",
					}}
				/>
			</div>
			<div
				sx={{
					position: "relative",
					width: "100%",
					height: "100%",
				}}
			>
				<div
					ref={containerRef}
					className="hide-scrollbars"
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						display: ["flex", "block"],
						flexWrap: "nowrap",
						gap: "4xs",
						overflowX: "auto",
						overflowY: "hidden",
						scrollSnapType: ["x mandatory", "none"],
						touchAction: "pan-x pan-y",
						"& > *": {
							width: "100%",
							scrollSnapAlign: "start",
							scrollSnapStop: "always",
							flexShrink: 0,
						},
					}}
				>
					{images.map((image, index) => (
						<div
							key={index}
							sx={{
								position: ["static", "absolute"],
								top: 0,
								bottom: 0,
								right: 0,
								left: 0,
								width: "100%",
								height: "100%",
								overflow: "hidden",
								display: ["flex", isImagePrepared(index) ? "flex" : "none"],
								alignItems: "center",
								justifyContent: "center",
								opacity: [1, activeIndex === index ? 1 : 0],
								transition: ["none", "opacity 0.5s ease-in"],
							}}
						>
							<Image
								objectFit="contain"
								fit="bounds"
								src={image.url}
								alt={image.description}
								width={[768, 1000, 1400]}
								height={[500, 700, 1000]}
								lazy={!(isImagePrepared(index) || loadedImages.current[index])}
								onLoadCallback={() => {
									loadedImages.current[index] = true;
								}}
								sx={{
									width: "100%",
									height: "100%",
								}}
								quality={quality}
							/>
						</div>
					))}
				</div>
				<Counter
					variant="Light"
					currentCount={activeIndex + 1}
					total={size}
					size="28"
					sx={{
						position: "absolute",
						bottom: "xs",
						left: "50%",
						zIndex: "modal",
						transform: "translateX(-50%)",
					}}
				/>
			</div>
			<div>
				<TriggerButton
					data-id="fullscreen-gallery-next"
					onTrigger={() => setActiveIndex(increaseIndex(activeIndex, size))}
					sx={commonButtonDesktopStyles}
				>
					<Icon size="40" name="Actions/MoveNext" />
				</TriggerButton>
				<Button
					data-id="fullscreen-gallery-mobile-previous"
					variant="Secondary"
					size="36"
					icon="Actions/MoveNext"
					sx={{
						...commonGalleryButtonMobileStyles,
						right: "3xs",
					}}
					onClick={() =>
						updateScrollImage({
							containerRef,
							newActiveIndex: increaseIndex(activeIndex, size),
							imageGap,
						})
					}
				/>
			</div>
		</div>
	);
};
